import React from "react"
import { Layout, ProjectsTable, SEO } from "../components"
import { ourWork } from "../content"
import { useContentfulOurWork } from "../components/hooks/useContentfulOurWork"

const OurWork = () => {
  const { metadata } = ourWork
  const { projects } = useContentfulOurWork()

  return (
    <Layout noHero title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <div className="our-work-page">
        <ProjectsTable projects={projects} displayAll={true} />
      </div>
    </Layout>
  )
}

export default OurWork
